import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function UnserAnspruchPage() {
  return (
    <Layout className="no-sidebar">
      <SEO title="Unser Anspruch" description="Unser Anspruch ist es, unseren Kunden zukunftsweisende Technologien auf dem Gebiet der Karbonisierung zu bieten" />

      <div className="wrapper">
        <div className="container" id="main">
          <article id="content">
            <header>
              <h2>Unser Anspruch</h2>
            </header>

            <p>Unser Anspruch ist es, unseren Kunden aus der Industrie, dem kommunalen Bereich sowie der Land- und Forstwirtschaft zukunftsweisende Technologien auf dem Gebiet der Karbonisierung, die sich in Deutschland und in anderen Ländern bereits vielfach bewähren, zu bieten. Durch geschlossene Wertstoffkreisläufe tragen die von uns angewandten ökonomisch und ökologisch sinnvollen Verfahren zur Reduzierung von CO<sub>2</sub>  Emissionen (verbesserter CO<sub>2</sub> Footprint) bei.</p>
            <p>Dass darüber hinaus diese Technologien nicht nur dazu beitragen,  unserer Umwelt und unseren nachfolgenden Generationen gegenüber verantwortungsvoll zu handeln, sondern dass die Investitionen hierin auch noch äußerst rentabel sein können, ist sicher einen weiteren Gedanken wert.</p>

            <div className="image featured"><Image /></div>
          </article>
        </div>
      </div>
    </Layout>
  )
}

function Image() {
  const { iceFloes } = useStaticQuery(graphql`
    query {
      iceFloes: file(relativePath: { eq: "william-bossen-120685-unsplash.jpg" }) {
        childImageSharp { fluid(maxWidth: 1200) { ...GatsbyImageSharpFluid } }
      }
    }
  `)

  return <Img fluid={iceFloes.childImageSharp.fluid} />
}
